#main-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#main-container form {
    width: 300px;
    display: flex;
    flex-direction: column;
}

#main-container form input {
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    padding: 0 20px;
    margin-top: 20px;
}

#main-container form button {
    height: 50px;
    background: rgb(3, 3, 70);
    border-radius: 4px;
    font-size: 16px;
    padding: 0 20px;
    margin-top: 10px;
    color: #fff;
    font-weight: bold;
    border:  0;
    cursor: pointer;
}

#main-container form button:hover {
    opacity: 0.8;
}

#main-container form img {
  padding: 0 75px;
}