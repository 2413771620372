* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
}

html,
body,
#root {
    height: 100%;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: #333;
    /* background: #333; */
    -webkit-font-smoothing: antialiased !important;
}
#main-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#main-container form {
    width: 300px;
    display: flex;
    flex-direction: column;
}

#main-container form input {
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    padding: 0 20px;
    margin-top: 20px;
}

#main-container form button {
    height: 50px;
    background: rgb(3, 3, 70);
    border-radius: 4px;
    font-size: 16px;
    padding: 0 20px;
    margin-top: 10px;
    color: #fff;
    font-weight: bold;
    border:  0;
    cursor: pointer;
}

#main-container form button:hover {
    opacity: 0.8;
}

#main-container form img {
  padding: 0 75px;
}
#box-container {
    max-width: 900px;
    margin: 50px auto 0;
  }
  
  #box-container header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #box-container header h1 {
    font-size: 21px;
    padding-left: 15px;
    margin-left: 15px;
    border-left: 1px solid #ddd;
  }
  
  #box-container header button svg {
    margin-right: 10px;
  }
  
  #box-container header button:hover {
    opacity: 0.9;
  }
  
  #box-container .upload {
    border-radius: 4px;
    padding: 30px;
    text-align: center;
    border: 1px dashed #ddd;
    color: #999;
    margin-top: 50px;
    cursor: pointer;
  }
  
  #box-container ul {
    margin: 30px 0;
    list-style: none;
  }
  
  #box-container ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  #box-container ul li + li {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #eee;
  }
  
  #box-container ul li .fileInfo {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  #box-container ul li .fileInfo strong {
    font-weight: normal;
    font-size: 14px;
    margin-left: 10px;
    color: #333;
  }
  
  #box-container ul li span {
    color: #999;
    font-size: 13px;
  }
